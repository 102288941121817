/**
 * Use the messaging bar item to display tertiary marketing messages and/or company information such as announcements, events or special promotions.
 *
 * @module views/components/MessageBarItem
 * @memberof -Common
 */
import './MessageBarItem.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

/**
 * Represents a MessageBarItem component
 *
 * @method
 * @param {MessageBarItemProps} props - React properties passed from composition
 * @returns MessageBarItem
 */
export const MessageBarItem = function( props ){
  const { inflection } = useDeviceInflection();
  return (
    <div className='MessageBarItem'>
      <Link_Huge
        target='_self'
        action={ props.action }
      >
        <div className='MessageBarItem__messsages'>
          <div
            className='MessageBarItem__Title'
          >
            <Text
              textAlign='center'
              textStyle='body-3'
            >
              {
                props.title
              }
            </Text>
          </div>
          <div className='MessageBarItem__Subtitle'>
            <Text
              textAlign='center'
              textStyle='body-3'
            >
              {
                props.subtitle
              }
            </Text>
          </div>
        </div>
        { props.action?.url && inflection.DESKTOP &&
        <div className='MessageBarItem__arrow'>
          <Text
            type='primary'
            htmlTag='span'
            color='black'
          >
            <Icon
              size='m'
              name='ArrowForward'
            />
          </Text>
        </div>
        }
      </Link_Huge>
    </div>
  );
};

/**
  * Property type definitions
  * @typedef MessageBarItemProps
  * @type {object}
  * @property {string} title - Sets the title properties
  * @property {string} subtitle - Sets the subtitle
  * @property {object} action - Sets the link action
  */

export const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.object
};



MessageBarItem.propTypes = propTypes;

export default MessageBarItem;
