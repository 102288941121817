/**
 * Use the global messaging bar to display tertiary marketing messages and/or company information such as announcements, events or special promotions.
 *
 * @module views/components/GlobalMessagingBar
 * @memberof -Common
 */
import './GlobalMessagingBar.scss';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import palette_variables from 'web-styles/src/_exports.module.scss';

import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { hasItems } from '@ulta/core/utils/array/array';

import MessageBarItem from '../MessageBarItem/MessageBarItem';

SwiperCore.use( [Pagination, A11y] );

/**
 * Represents a GlobalMessagingBar component
 *
 * @method
 * @param {GlobalMessagingBarProps} props - React properties passed from composition
 * @returns GlobalMessagingBar
 */

export const GlobalMessagingBar = React.forwardRef( ( props, _ ) => {
  const { items } = props;
  const { inflection } = useDeviceInflection();

  if( !hasItems( items ) ){
    return null;
  }

  const GlobalMessagingBarContents = items.map( ( content, index ) => (
    inflection.DESKTOP ? (
      <div key={ index }
        className={ classNames( {
          [`GlobalMessagingBar__wrapper--content-${index + 1}`]: inflection.DESKTOP
        } )
        }
      >
        <MessageBarItem
          title={ content.title }
          subtitle={ content.subtitle }
          action={ content.action }
        />
      </div >
    ) : (
      <SwiperSlide
        key={ index }
        className={ classNames( {
          [`GlobalMessagingBar__wrapper--content-mobile`]: inflection.MOBILE
        } )
        }
      >
        <MessageBarItem
          title={ content.title }
          subtitle={ content.subtitle }
          action={ content.action }
        />
      </SwiperSlide>
    )
  ) );

  const messageLength = items.length;

  return (
    <div className='GlobalMessagingBar'>
      <div className='GlobalMessagingBar__Wrapper'>
        <div className={ classNames( 'GlobalMessagingBar__content ', {
          'GlobalMessagingBar__wrapper--Single-Message': inflection.DESKTOP && messageLength === 1,
          'GlobalMessagingBar__wrapper--Two-Messages': inflection.DESKTOP && messageLength === 2,
          'GlobalMessagingBar__wrapper--Three-Messages': inflection.DESKTOP && messageLength === 3,
          'GlobalMessagingBar__wrapper-Mobile-Single-Message': inflection.MOBILE && messageLength === 1,
          'GlobalMessagingBar__wrapper-Mobile-Messages': inflection.MOBILE && messageLength > 1
        } )
        }
        >
          { inflection.MOBILE && messageLength > 1 &&
            <Swiper
              navigation={ false }
              pagination={ {
                type: 'bullets',
                clickable: 'true'
              } }
              responsive={ {
                tablet: {
                  breakpoint: {
                    max: 1000,
                    min: parseInt( palette_variables.breakpointMD, 10 )
                  },
                  items: 1
                },
                mobile: {
                  breakpoint: {
                    max: parseInt( palette_variables.breakpointMD, 10 ) - 1,
                    min: parseInt( palette_variables.breakpointSM, 10 )
                  },
                  items: 1
                }
              }
              }
            >
              { GlobalMessagingBarContents }
            </Swiper>
          }
          { ( ( inflection.DESKTOP ) || ( inflection.MOBILE && messageLength === 1 ) ) &&
            GlobalMessagingBarContents
          }
        </div>
      </div>
    </div>
  );
} );

/**
  * Property type definitions
  * @typedef GlobalMessagingBarProps
  * @type {object}
  * @property {array} items - The elements to display as slides in the swiper
  */

export const propTypes = {
  items: PropTypes.array.isRequired
};

GlobalMessagingBar.propTypes = propTypes;
GlobalMessagingBar.displayName = 'GlobalMessagingBar';

export default GlobalMessagingBar;
